<template>
  <v-layout column>
    <FieldHeader
      v-if="!hideHeader"
      :field="field"
      :patient="patient"
      :session.sync="session"
      :owner="owner"
      :hideCollapseBtn="hideCollapseBtn"
      :collapsed="hideBody"
      @history="onHistory"
      @collapse="onCollapse"
      :status="status"
      :name="name"
      autosave
    ></FieldHeader>
    <v-layout 
        v-if="!disabled && !hideBody">
        <SubviewCard
        class="pa-3 pt-5"
        noActions
        :padding="false"
        style="width: 100%"
        >
        <ChildActivityAssessmentForm
            :value.sync="data"
        ></ChildActivityAssessmentForm>
        </SubviewCard>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :hideEdit="hideEdit"
      :session="session"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
      @copy="onCopy"
    >
      <SubviewCard class="mx-6" noActions :padding="false" style="width: 100%">
        <ChildActivityAssessmentForm
          :value="data"
          class="my-2"
          disabled
        ></ChildActivityAssessmentForm>
      </SubviewCard>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    data: {
      get() {
        if (!this.field.data) {
          return {
            head_torso_axis: "",
            upper_lower_limbs: "",
            abdominal_border_muscles: "",
            oral_cavity: "",
            gross_motor_skills: "",
            fine_motor_skill: "",
            sensorcis: "",
            structural_analysis: "",
            other: ""
          };
        } else {
          return this.field.data;
        }
      },
      set(value) {
        this.$set(this.field, "data", value);
      },
    },
    dataJson: {
      get() {
        return JSON.stringify(this.data);
      },
    },
  },
  components: {
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
    ChildActivityAssessmentForm: () =>
      import("@/components/forms/patientcards/ChildActivityAssessmentForm"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onCopy() {
      this.$emit("copy");
    }
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      this.startWatching("dataJson");
    }
  },
};
</script>